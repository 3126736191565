import { NgComponentOutlet } from '@angular/common';
import {
  AfterViewInit,
  booleanAttribute,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  EventEmitter,
  inject,
  Input,
  numberAttribute,
  OnInit,
  Output,
  QueryList,
  signal,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { AbstractControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatStepper, MatStepperModule } from '@angular/material/stepper';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';

import { OrmStep } from '@shared/components';
import { IsFalsePipe, IsNotEmptyPipe, IsNotNilPipe } from '@shared/pipe';
import { isEmpty, isNil, isNotEmpty, isNotNil } from '@shared/utils';

import { WizardForm, WizardFormMainKey, WizardFormSubKey } from '../../../wizard/wizard-form';

@Component({
  selector: 'app-stepper',
  template: `
    <form class="stepper-container" [formGroup]="form">
      <mat-vertical-stepper #mainStepper [linear]="linearMode()" (selectedIndexChange)="onMainStepperSelectedIndexChange($event)">
        @for (step of ormSteps; track step.index; let parentStepperIndex = $index) {
          <mat-step [completed]="false" [formGroupName]="step.stepControlKey" [stepControl]="form.get(step.stepControlKey)">
            <ng-template matStepLabel>
              <strong>{{ step.label }}</strong>
            </ng-template>
            <ng-template matStepContent>
              @if (step.subSteps | isNotEmpty) {
                <mat-vertical-stepper
                  class="sub-stepper"
                  #subStepper
                  [linear]="linearMode()"
                  (selectedIndexChange)="onSubStepperSelectedIndexChange($event)"
                >
                  @for (subStep of step.subSteps; track subStep.index; let nestedStepperIndex = $index) {
                    <mat-step [completed]="false" [stepControl]="form.get(step.stepControlKey).get(subStep.stepControlKey)">
                      <ng-template matStepperIcon></ng-template>
                      <ng-template matStepLabel>
                        <strong>{{ subStep.label }}</strong>
                      </ng-template>
                    </mat-step>
                  }
                </mat-vertical-stepper>
              } @else {
                <mat-vertical-stepper class="empty-sub-stepper" #subStepper [linear]="linearMode()">
                  <mat-step></mat-step>
                </mat-vertical-stepper>
              }
            </ng-template>
          </mat-step>
        }
      </mat-vertical-stepper>
      <div class="step-content">
        @if (route() | isNotNil) {
          <router-outlet></router-outlet>
        }
        @if (selected() | isNotNil) {
          <div class="footer">
            <div class="mat-headline-6 number-of-steps">Step {{ currentIndex() }} of {{ stepsLength() }}</div>
            <div class="buttons">
              <button [disabled]="loading" (click)="onSecondaryButtonClick()" color="primary" mat-stroked-button>
                @if (selected().buttons?.secondary) {
                  {{ selected().buttons.secondary.label }}
                } @else {
                  Back
                }
              </button>

              <button [disabled]="loading" (click)="onPrimaryButtonClick()" color="primary" mat-flat-button>
                @if (loading | isFalse) {
                  @if (selected().buttons?.primary) {
                    {{ selected().buttons.primary.label }}
                  } @else {
                    Next
                  }
                } @else {
                  <mat-spinner [diameter]="16" color="primary"></mat-spinner>
                }
              </button>

              @if (selected().buttons?.tertiary) {
                <button [hidden]="isTertiaryButtonHidden()" (click)="onTertiaryButtonClick()" color="primary" mat-button>
                  {{ selected().buttons.tertiary.label }}
                </button>
              }
            </div>
          </div>
        }
      </div>
    </form>
  `,
  standalone: true,
  styles: [
    `
      .stepper-container {
        height: calc(100% - 120px);
        width: 100%;
        display: grid;
        grid-template-columns: 0.5fr 5fr;

        .step-content {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          padding: 0 1rem;
          height: 100%;

          .footer {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .buttons {
              display: flex;
              align-items: center;
              gap: 0.5rem;
            }
          }
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatStepperModule,
    NgComponentOutlet,
    IsNotEmptyPipe,
    MatButton,
    RouterOutlet,
    IsNotNilPipe,
    IsFalsePipe,
    MatProgressSpinner,
    ReactiveFormsModule,
  ],
})
export class StepperComponent<
    TControl extends {
      [K in keyof TControl]: AbstractControl;
    } = WizardForm,
    MainKey extends string = WizardFormMainKey,
    SubKey extends string = WizardFormSubKey,
  >
  implements OnInit, AfterViewInit
{
  @Input({
    required: true,
  })
  ormSteps: OrmStep<MainKey, SubKey>[];

  @Input({
    required: true,
  })
  form: FormGroup<TControl>;

  @Input({
    transform: numberAttribute,
  })
  initialIndex = 0;

  @Input({
    transform: numberAttribute,
  })
  initialMainStepperIndex = 0;

  @Input({
    transform: booleanAttribute,
  })
  loading = false;

  @ViewChild('mainStepper', { static: true })
  readonly mainStepper: MatStepper;
  @ViewChildren('subStepper')
  readonly subSteppers: QueryList<MatStepper>;

  @Output()
  stepValidationChanged = new EventEmitter<void>();
  @Output()
  primaryButtonClick = new EventEmitter<void>();
  @Output()
  tertiaryButtonClick = new EventEmitter<void>();

  readonly isTertiaryButtonHidden = signal(false);
  readonly selected = signal<OrmStep>(undefined);
  readonly currentIndex = computed<number>(() => this.selected()?.stepperIndex ?? 1);
  readonly stepsLength = computed<number>(() =>
    isNotEmpty(this.ormSteps) ? this.ormSteps[this.mainStepper.selectedIndex].subSteps.length : 0
  );
  readonly route = computed<string | undefined>(() => {
    const selected = this.selected();
    return selected?.route;
  });

  protected linearMode = signal(true);

  private readonly router = inject(Router);
  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly cdr = inject(ChangeDetectorRef);

  ngOnInit(): void {
    if (isNotEmpty(this.ormSteps)) {
      this.selected.set(this.ormSteps[this.initialMainStepperIndex].subSteps[this.initialIndex]);
    }
  }

  ngAfterViewInit(): void {
    this.markAllAlreadyVisitedStepsAsInteracted();

    if (isNotNil(this.initialMainStepperIndex) && !isNaN(this.initialMainStepperIndex)) {
      this.mainStepper.selectedIndex = this.initialMainStepperIndex;
      this.cdr.detectChanges();
    }

    if (isNotNil(this.initialIndex) && !isNaN(this.initialIndex) && isNotNil(this.getCurrentSubStepper())) {
      this.getCurrentSubStepper().selectedIndex = this.getInitialIndexBasedOnCurrentSubStepper();
      this.cdr.detectChanges();
    }

    this.markAllAlreadyVisitedStepsAsSubmitted();
  }

  onPrimaryButtonClick(): void {
    const currentSubStepper = this.getCurrentSubStepper();
    if (isNil(currentSubStepper.selected.stepControl)) {
      this.primaryButtonClick.emit();
      return;
    }
    currentSubStepper.selected.stepControl.markAllAsTouched();
    if (currentSubStepper.selected.stepControl.invalid) {
      this.getCurrentSubStepper().selected.stepControl.markAllAsTouched();
      this.stepValidationChanged.emit();
      return;
    }

    currentSubStepper.selected.submitted = true;

    if (currentSubStepper.steps.length === currentSubStepper.selectedIndex + 1) {
      this.mainStepper.selected.submitted = true;
    }

    this.primaryButtonClick.emit();
  }

  nextStep(): void {
    const currentSubStepper = this.getCurrentSubStepper();
    if (isNotNil(currentSubStepper) && currentSubStepper.steps.length !== currentSubStepper.selectedIndex + 1) {
      currentSubStepper.next();
    } else {
      this.mainStepper.next();
    }
  }

  onSecondaryButtonClick(): void {
    const currentSubStepper = this.getCurrentSubStepper();
    if (isNotNil(currentSubStepper) && currentSubStepper.selectedIndex !== 0) {
      currentSubStepper.previous();
    } else {
      const initialRoute = this.findInitialRoute();
      if (this.mainStepper.selectedIndex === 0 && isNotNil(initialRoute)) {
        this.router.navigate([`/${initialRoute}`]);
      } else {
        this.mainStepper.previous();
      }
    }
  }

  onTertiaryButtonClick(): void {
    this.tertiaryButtonClick.emit();
  }

  onMainStepperSelectedIndexChange(index: number): void {
    this.selected.set(this.getSelectedStep(index));
    if (isNotNil(this.route())) {
      this.router.navigate([this.route()], {
        relativeTo: this.activatedRoute,
        queryParamsHandling: 'preserve',
      });
    }
  }

  onSubStepperSelectedIndexChange(index: number): void {
    this.selected.set(this.getSelectedSubStep(index));
    if (isNotNil(this.route())) {
      this.router.navigate([this.route()], {
        relativeTo: this.activatedRoute,
        queryParamsHandling: 'preserve',
      });
    }
  }

  setStepsAsNotSubmitted(indexes: number[]): void {
    indexes.forEach(index => {
      const step = this.getCurrentSubStepper().steps.toArray()[index];

      if (isNil(step)) {
        return;
      }

      step.submitted = false;
    });
  }

  private getInitialIndexBasedOnCurrentSubStepper(): number {
    if (this.initialMainStepperIndex === 0 || isNaN(this.initialMainStepperIndex)) {
      return this.initialIndex;
    }
    return this.initialIndex - this.ormSteps[this.initialMainStepperIndex].subSteps.length;
  }

  private getCurrentSubStepper(): MatStepper | undefined {
    const mainStepperIndex = this.mainStepper.selectedIndex;

    if (isEmpty(this.ormSteps[mainStepperIndex].subSteps) || isEmpty(this.subSteppers)) {
      return undefined;
    }

    return this.subSteppers.get(this.mainStepper.selectedIndex);
  }

  private getSelectedStep(index: number): OrmStep | undefined {
    if (isEmpty(this.ormSteps)) {
      return undefined;
    }

    if (isNotEmpty(this.ormSteps[index].subSteps)) {
      return this.ormSteps[index].subSteps[0];
    }

    return this.ormSteps[index];
  }

  private getSelectedSubStep(index: number): OrmStep | undefined {
    const mainStepperIndex = this.mainStepper.selectedIndex;

    if (isEmpty(this.ormSteps[mainStepperIndex].subSteps)) {
      return undefined;
    }

    return this.ormSteps[mainStepperIndex].subSteps[index];
  }

  private markAllAlreadyVisitedStepsAsInteracted(): void {
    this.getCurrentSubStepper()
      .steps.toArray()
      .slice(0, this.initialIndex)
      .forEach(step => {
        step.interacted = true;
        step.submitted = true;
      });

    this.mainStepper.steps
      .toArray()
      .slice(0, this.initialMainStepperIndex)
      .forEach(step => {
        step.interacted = true;
        step.submitted = true;
      });
  }

  private markAllAlreadyVisitedStepsAsSubmitted(): void {
    this.getCurrentSubStepper()
      .steps.toArray()
      .slice(0, this.getInitialIndexBasedOnCurrentSubStepper())
      .forEach(step => {
        step.submitted = true;
      });

    this.mainStepper.steps
      .toArray()
      .slice(0, this.initialMainStepperIndex)
      .forEach(step => {
        step.submitted = true;
      });
  }

  private findInitialRoute(): string | undefined {
    return this.ormSteps.find(step => step.initialRoute)?.initialRoute;
  }
}
