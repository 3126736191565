export const environment = {
  prod: false,
  production: false,
  apiUrl: 'https://oneorm-dev-api.gfitdnsdev.swissre.com',
  canDisplayWizard: true,
  dateFormat: 'DD-MM-YYYY',
  msalConfig: {
    clientId: '226e1293-1aa5-4d8a-9123-b27b978b0b1f',
    authority: 'https://login.microsoftonline.com/45597f60-6e37-4be7-acfb-4c9e23b261ea/',
    scope: 'api://3c23d503-3dbb-45f1-b2f7-ea37c4b65293/user_impersonation',
  },
  tenantId: '45597f60-6e37-4be7-acfb-4c9e23b261ea',
  redirectUri: 'https://oneorm-dev.gfitdnsdev.swissre.com/',
  updateRCSA:
    'https://graceop-ite.swissre.com/grb/app/jspview/flv?start=compliance.objectFolderView.RiskAssessment&target=RiskAssessment&efv=true&ctxtId=75&ctxt=RiskAssessment&prmtId=75&prmt=RiskAssessment',
  requestControlChange: 'https://graceop-ite.swissre.com/grb/services/ccmt/#/dashboard',
  graphUrl: 'https://graph.microsoft.com/beta/',
  viewIssue: true,
  rcsaGraceUrl: 'https://graceop-dev.swissre.com:443/grb/view.resource.do?fileId=',
  riskGraceUrl: 'https://graceop-dev.swissre.com:443/grb/view.resource.do?fileId=',
  controlGraceUrl: 'https://graceop-dev.swissre.com:443/grb/view.resource.do?fileId=',
  eventGraceUrl: 'https://graceop-dev.swissre.com:443/grb/view.resource.do?fileId=',
  envType: 'DEV',
  timeZone: 'CET',
  timeFormat: 'dd MMM yyyy',
  oneOrmUrl: 'https://oneorm-dev.gfitdnsdev.swissre.com/',
  faqUrl: 'https://swissre.sharepoint.com/sites/team-GRACE/SitePages/OneORM-RCSA---Frequently-Asked-Questions-%28FAQ%29.aspx',
  graceUrl: 'https://graceop-dev.swissre.com/grb/services/Issue/IssueWorkFlow.jsp?valueOfRCSA=CS&resourceId=',
  oneOrmNewRiskCaseUrl: 'https://oneorm-dev.gfitdnsdev.swissre.com/#/new-risk?id=',
  OperationalRiskDashboard:
    'https://app.powerbi.com/Redirect?action=OpenApp&appId=2ac9c36d-bdb0-4593-bc17-65871cda74a8&ctid=45597f60-6e37-4be7-acfb-4c9e23b261ea',
  CCMT: 'https://graceop-ite.swissre.com/grb/services/ccmt/index.jsp?valueOfRCSA=SA&resourceId=',
  powerBIrisks:
    'https://app.powerbi.com/rdlEmbed?reportId=ce16571d-f260-499c-a641-6554b816ae07&autoAuth=true&ctid=45597f60-6e37-4be7-acfb-4c9e23b261ea',
  powerBIcontrols:
    'https://app.powerbi.com/rdlEmbed?reportId=6321b43d-a7ba-44d4-815f-26b8465d22ee&autoAuth=true&ctid=45597f60-6e37-4be7-acfb-4c9e23b261ea',
  powerBIcontrolsInOtherDept:
    'https://app.powerbi.com/rdlEmbed?reportId=aab2dec3-e8a0-47cc-aa65-4ef194fee1b2&autoAuth=true&ctid=45597f60-6e37-4be7-acfb-4c9e23b261ea',
  powerBIevents:
    'https://app.powerbi.com/rdlEmbed?reportId=20271fbc-5b27-40ee-999b-4047ae294454&autoAuth=true&ctid=45597f60-6e37-4be7-acfb-4c9e23b261ea',
  powerBIissues:
    'https://app.powerbi.com/rdlEmbed?reportId=4949f9b4-d4d2-4f3e-bbce-3b1198b46e82&autoAuth=true&ctid=45597f60-6e37-4be7-acfb-4c9e23b261ea',
  powerBIrcsa:
    'https://app.powerbi.com/rdlEmbed?reportId=716cac6c-d8cc-47b9-8d96-9b69a17393f7&autoAuth=true&ctid=45597f60-6e37-4be7-acfb-4c9e23b261ea',
  textAnalysisEndpoint: 'https://oneorm-dev-func-app-text.azurewebsites.net/api/tqc/',
};
